import React, { useState } from 'react';
import { ReactComponent as DownArrow } from '../../images/svgs/down-arrow.svg';

interface Props {
    options: Array<string>;
    initial?: string;
    handleChange?: (val: string) => void;
}

export const Select = ({ options, initial = '', handleChange }: Props) => {
    const [selected, setSelected] = useState(initial || options[0]);
    const [showOptions, setShowOptions] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState([
        ...options.filter(option => option !== initial),
    ]);

    const onChange = (value: string) => {
        setSelected(value);
        setShowOptions(false);
        const updatedOptions = options.filter(option => option !== value);
        setFilteredOptions(updatedOptions);
        if (handleChange) {
            handleChange(value);
        }
    };

    return (
        <div className="min-w-[85px]">
            <button
                className={`rounded-lg BlogButton p-3 cursor-pointer w-full border-[3px] border-solid-white ${
                    showOptions && 'rounded-b-none '
                }  `}
                onClick={() => setShowOptions(prev => !prev)}
            >
                <div className="flex items-center justify-center">
                    <p className="text-white mr-2">{selected}</p>
                    <div className="mr-2">
                        <DownArrow />
                    </div>
                </div>
            </button>
            <div className="relative">
                {options.length && showOptions && (
                    <ul
                        className={`text-white text-center absolute w-full cursor-pointer rounded-lg BlogButton z-10 bg-primary ${
                            showOptions &&
                            'border-[3px] border-solid-white border-t-transparent rounded-t-none'
                        }   `}
                    >
                        {filteredOptions.map((option, i) => (
                            <div
                                key={i}
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => onChange(option)}
                                className={`notranslate cursor-pointer p-2 hover:bg-[#296c60] overflow-hidden ${
                                    filteredOptions.length - 1 == i && 'rounded-b-lg'
                                }`}
                                onClick={() => onChange(option)}
                            >
                                {option}
                            </div>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};
