export const HomePageOfficeTour = [
    { pic: 'office.jpeg' },
    { pic: 'doctor.jpeg' },
    { pic: 'dental-machine.jpeg' },
    { pic: 'office1.jpeg' },
    { pic: 'office2.jpeg' },
    { pic: 'office4.jpeg' },
    { pic: 'office5.jpeg' },
    { pic: 'office6.jpeg' },
    { pic: 'office7.jpeg' },
    { pic: 'office8.jpg' },
];

export const OfficeTourPageCarousel = [
    { pic: 'office.jpeg', name: 'Outside Office' },
    { pic: 'doctor.jpeg', name: 'Dr Zapata' },
    { pic: 'office1.jpeg', name: 'Waiting Lobby' },
    { pic: 'office2.jpeg', name: 'Office Desk' },
    { pic: 'office5.jpeg', name: 'Live Aquarium' },
    { pic: 'office6.jpeg', name: 'Dentist Rooms' },
    { pic: 'dental-machine.jpeg', name: 'Equipment' },
];
