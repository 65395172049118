import React, { useState } from 'react';
import Image from '@/common/image';
import { Drawer } from './drawer';
import Backdrop from '@/common/backdrop';
import Button from '@/common/button';
import { navLinks } from '../../constants';
import { Link } from 'gatsby';
import { ReactComponent as Menu } from '../../images/svgs/menu-close.svg';
import { Select } from '@/common/select';

export default function Header() {
    const [openDrawer, setOpenDrawer]: [boolean, any] = useState(false);
    const options = ['Eng', 'Esp'];

    const currentLanguage =
        typeof document !== 'undefined' && document.cookie.includes('googtrans=/en/es')
            ? 'Esp'
            : 'Eng';

    const handleLanguageChange = (lang: string) => {
        document.cookie = `googtrans=;domain=.${window.location.hostname};expires=${new Date(
            0
        ).toUTCString()}`;
        document.cookie = `googtrans=/en/${lang.toLowerCase().slice(0, 2)};`;
        window.location.reload();
    };

    return (
        <>
            <Backdrop show={openDrawer} onClick={() => setOpenDrawer(false)} />
            <Drawer open={openDrawer} setOpen={setOpenDrawer} />
            <div className="bg-primary text-white">
                <div className="flex  items-center justify-between w-[95%] mx-auto py-[20px]">
                    <div className="py-[6px]">
                        <Link to="/">
                            <Image
                                className="w-[125px] xl:w-[150px] 2xl:w-[200px] cursor-pointer"
                                filename="zapata-logo.png"
                                alt="header-logo"
                            />
                        </Link>
                    </div>
                    <div className="hidden xl:flex sm:flex-wrap items-center HeaderLink py-[6px]">
                        {navLinks.map(({ label, link }, i) => (
                            <Link to={link} key={i} className="mx-3 cursor-pointer">
                                {label}
                            </Link>
                        ))}
                    </div>
                    <div className="hidden xl:flex sm:items-center py-[6px]">
                        <a href="tel:+6238460575">
                            <Button
                                phone
                                text="(623) 846-0575"
                                className="bg-secondaryRed xl:mr-4 2xl:mr-8 md:py-[14px] md:px-4"
                            />
                        </a>
                        <Select
                            options={options}
                            initial={currentLanguage}
                            handleChange={handleLanguageChange}
                        />
                    </div>
                    <div
                        className="xl:hidden"
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => setOpenDrawer(!openDrawer)}
                        onClick={() => setOpenDrawer(!openDrawer)}
                    >
                        <Menu />
                    </div>
                </div>
            </div>
        </>
    );
}
