export const testimonials = [
    {
        name: 'Alena Mangerin',
        description:
            ' I take my mother to see Dr. Zapata once a month right now, she just got her teeth fixed. We love the staff and him theyre all very friendly and sweet! We love them all. They always makes her feel comfortable and are extremely patient with her.',
    },
    {
        name: 'Alena ',
        description:
            ' I take my mother to see Dr. Zapata once a month right now, she just got her teeth fixed. We love the staff and him theyre all very friendly and sweet! We love them all. They always makes her feel comfortable and are extremely patient with her.',
    },
    {
        name: 'Mangerin',
        description:
            ' I take my mother to see Dr. Zapata once a month right now, she just got her teeth fixed. We love the staff and him theyre all very friendly and sweet! We love them all. They always makes her feel comfortable and are extremely patient with her.',
    },
];
