import { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { navLinks } from '../../constants';
import Image from '@/common/image';
import { ReactComponent as Menu } from '../../images/svgs/menu-open.svg';
import '../layout.css';

interface Props {
    open: boolean;
    setOpen: () => void;
}

export const Drawer = ({ open, setOpen }: Props) => {
    const [attachedClasses, setAttachedClasses] = useState('sideDrawer close');
    useEffect(() => {
        if (open) {
            document.getElementsByTagName('body')[0].className = 'overflow-hidden';
            setAttachedClasses('sideDrawer open');
        } else {
            setAttachedClasses('sideDrawer close');
            document.getElementsByTagName('body')[0].className = 'overflow-auto';
        }
    }, [open]);

    return (
        <div className={attachedClasses}>
            <div className="flex justify-between mx-5 py-1 ">
                <Image
                    className="w-[125px] cursor-pointer"
                    filename="zapata-logo.png"
                    alt="header-logo"
                />
                <div
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => setOpen(false)}
                    onClick={() => setOpen(false)}
                >
                    <Menu />
                </div>
            </div>
            <div className="mt-[64px] mb-[80px] flex flex-col">
                <div className="flex flex-col">
                    {navLinks.map(({ label, link }, i) => (
                        <Link
                            to={link}
                            key={i}
                            className="text-lg font-[500] leading-[40px] mb-[15px]"
                            onClick={() => setOpen(false)}
                        >
                            {label}
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};
