import Image from '@/common/image';
import { Link } from 'gatsby';
import { ReactComponent as ArrowRight } from '../../images/svgs/arrow-right.svg';
interface props {
    className?: string;
    type?: 'button' | 'submit' | 'reset';
    text?: string;
    phone?: boolean;
    link?: string;
    arrow?: boolean;
    greenRightArrow?: boolean;
    onClick?: () => void;
    disabled?: boolean;
}
const Button = ({
    className,
    text,
    type,
    phone,
    arrow,
    greenRightArrow,
    disabled,
    onClick,
    link,
}: props) => {
    const Button = (
        <button
            disabled={disabled}
            type={type}
            onClick={onClick}
            className={`flex items-center py-[11px] px-4 md:py-4 md:px-6 rounded-lg BlogButton ${className}`}
        >
            {phone && <Image className="w-[14px]  mr-2" filename="phonebtn.png" alt="card" />}
            {text}
            {arrow && <ArrowRight className="ml-2" />}
            {greenRightArrow && (
                <Image className="w-[14px]  ml-2" filename="green-arrow-right.png" alt="arm" />
            )}
        </button>
    );

    if (link) {
        return <Link to={link}>{Button}</Link>;
    }

    return Button;
};
export default Button;
